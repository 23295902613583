import CustomToast from "@/components/Utilities/CustomToast";
import { basePath } from "next.config";
import { useState } from "react";
import { getAuth } from "services/identity.service";

export default function Home() {
  const [show, setShow] = useState(false);
  return (
    <div className='text-center'>
      <main>
        <h1 className="mt-4">
          Welcome to BlissClub Admin
        </h1>
      </main>
      <CustomToast show={show} message={'copied to clipboard'} type={'success'}
        onClose={() => {
          setShow(false);
        }} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="cursor-pointer" style={{ margin: "200px 0px 24px", width: "200px" }} onClick={() => { navigator.clipboard.writeText(getAuth()?.token); setShow(true) }}>Copy Admin Token
          <img
            src={basePath + "/images/copy.svg"}
            alt=""
            height="22"
            className="ml-2"
          /></div>
      </div>
      <a href={process.env.NEXT_PUBLIC_API_URL + '/swagger/'} target="_blank">Admin Apis</a>
    </div>
  )
}