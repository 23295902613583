import { Toast, ToastBody } from 'reactstrap';

export default function CustomToast({
    message, show, onClose, type = 'success'
}) {
    return (
        <div
            className="position-absolute"
            style={{ zIndex: 1000, right: "80px" }}
        >
            <Toast
                isOpen={show}
                className={type === 'success' ? "bg-success text-white" : "bg-danger text-white"}
            >
                <ToastBody className="d-flex align-items-center justify-content-between">
                    {message}
                    <i className="mdi mdi-window-close cursor-pointer" onClick={onClose}></i>
                </ToastBody>
            </Toast>
        </div>
    )
}